import { Component } from '@angular/core';

@Component({
  selector: 'pn-table-controls',
  standalone: true,
  imports: [],
  templateUrl: './table-controls.component.html',
  styleUrl: './table-controls.component.scss',
})
export class TableControlsComponent {}
