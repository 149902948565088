/* src/app/_shared/components/tables/table-sorting-button/table-sorting-button.component.scss */
:root {
  --icon-fill: #000;
  --icon-stroke: #000;
}
:host {
  display: block;
}
.button {
  display: inline-flex;
  gap: 5px;
  align-items: center;
  padding: 0;
  border: none;
  margin: 0;
  background: transparent;
  cursor: pointer;
  outline: none;
  font-weight: 400;
}
.button pn-icon {
  display: block;
}
.button.active {
  color: #338054;
  font-weight: 700;
  --icon-fill: #338054;
  --icon-stroke: #338054;
}
.button .button-icon {
  flex: 0 0 18px;
  width: 18px;
}
/*# sourceMappingURL=table-sorting-button.component.css.map */
