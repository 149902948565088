import { Component, input, model, output } from '@angular/core';
import { IconComponent } from '@shared/components/base/icon/icon.component';

export interface TableSortingButtonEvent {
  event: Event;
  sortAscending: boolean;
}

@Component({
  selector: 'pn-table-sorting-button',
  standalone: true,
  imports: [IconComponent],
  templateUrl: './table-sorting-button.component.html',
  styleUrl: './table-sorting-button.component.scss',
})
export class TableSortingButtonComponent {
  disabled = input<boolean>(false);
  tabIndex = input<number>(0);
  sortAscending = model<boolean>(true);
  active = input<boolean>(false);

  buttonClick = output<TableSortingButtonEvent>();

  onClick(event: Event) {
    if (this.active()) {
      this.sortAscending.set(!this.sortAscending());
    }
    if (!this.disabled()) {
      this.buttonClick.emit({ event, sortAscending: this.sortAscending() });
    }
  }
}
