<button
  class="button"
  [class.active]="active()"
  type="button"
  [disabled]="disabled()"
  [tabIndex]="tabIndex()"
  (click)="onClick($event)"
>
  <span class="button-text">
    <ng-content></ng-content>
  </span>
  <span class="button-icon">
    @if (sortAscending()) {
      <pn-icon icon="sort-bottom"></pn-icon>
    } @else {
      <pn-icon icon="sort-top"></pn-icon>
    }
  </span>
</button>
